<template>
  <div class="liveLottery">
    <div v-title data-title="虚拟币帮助"></div>

    <!-- <van-nav-bar
      title="真人闯关"
      left-arrow
      :fixed="true"
      @click-left="gotoback"
    /> -->

    <div class="imgbox">
      <img :src="`${imgUrl}pcbb/bg-Withdrawalshelp.jpg`" />
    </div>

    <div class="changebox">
      <div class="changebox-item " :class="tabindex == 1 ? 'onchangetab' : ''" @click="Submit(1)">
        1.下载注册登录
      </div>
      <div class="changebox-item" :class="tabindex == 2 ? 'onchangetab' : ''" @click="Submit(2)">
        2.绑定钱包
      </div>
      <div class="changebox-item" :class="tabindex == 3 ? 'onchangetab' : ''" @click="Submit(3)">
        3.购买USDT
      </div>
      <div class="changebox-item" :class="tabindex == 4 ? 'onchangetab' : ''" @click="Submit(4)">
        4.平台转账
      </div>
      <div class="changebox-item" :class="tabindex == 5 ? 'onchangetab' : ''" @click="Submit(5)">
        5.货币提现
      </div>
    </div>

    <div class="newpepele-item" v-if="tabindex == 1" @click="gotoutl('https://www.lybg1688.com/download')">
      <img :src="`${imgUrl}helpimg1.png`" style="width: 100%;"></img>
    </div>

    <div class="newpepele-item" v-if="tabindex == 2">
      <img :src="`${imgUrl}helpimg2.png`" style="width: 100%;"></img>
    </div>

    <div class="newpepele-item" v-if="tabindex == 3">
      <img :src="`${imgUrl}helpimg3.png`" style="width: 100%;"></img>
    </div>

    <div class="newpepele-item" v-if="tabindex == 4">
      <img :src="`${imgUrl}helpimg4.png`" style="width: 100%;"></img>
    </div>

    <div class="newpepele-item" v-if="tabindex == 5">
      <img :src="`${imgUrl}helpimg5.png`" style="width: 100%;"></img>
    </div>

    <div class="rule-big-box">
      <div class="rule-top-img"><img src="../assets/img/pcbb/rules_1.png" v-if="cueTheme == 'dattheme'" />
        <img :src="`${imgUrl}pcbb/rules.png`" v-else />
      </div>
      <div class="rule-middle-img">
        <div class="rule-content">
          <div class="rule-content-title" style="margin-top:0"></div>
          <div class="activity_rules">
            <ul>
              <li>
                完成首次虚似币存款即可获得68彩金，1倍流水即可提款。
              </li>
            </ul>
          </div>
        </div>

        <!-- 活动详情 -->
        <div class="rule-detail">
          <div class="rule-detail-title"></div>
          <div class="activity_rules">
            <ul>
              <li>
                <span>1.</span>
                参与条件:会员需要先在“我的”->“账户管理”->“虚拟币钱包”内绑定正确的虚拟币地址。
              </li>
              <li>
                <span>2.</span>
                存款奖励:会员等级需要VIP3以上，首次完成1笔100USDT或以上的虚拟币存款后，系统在24小时内自动发放 68 彩金奖励。
              </li>
              <li>
                <span>3.</span>
                会员可在页面内阅读学习虚拟币使用教程，如有不解之处可联系客服协助。
              </li>
              <li>
                <span>4.</span>
                为避免文字争议，此活动遵循一般活动规则与条款，并由贝宝保留最终解释权。
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="rule-bottom-img"></div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      tabindex: 1,
      cueTheme: ''

    }
  },
  methods: {


    Submit (index) {
      if (index == this.tabindex) return;
      this.tabindex = index
    },

    gotoback () {
      if (window.localStorage.getItem('opentype') == 'h5') {
        uni.navigateBack({})
      } else {
        uni.getEnv((res) => {
          uni.postMessage({
            data: {
              isclose: 'true'
            }
          })
        })
      }
    },
    gotoutl (url) {
      uni.getEnv((res) => {
        if (Object.keys(res)[0] === 'h5') {
          window.open(url)
        } else {
          // 申请
          uni.postMessage({
            data: {
              openUrl: url
            }
          })
        }
      })
    }
  },
  mounted () {
    this.cueTheme = window.localStorage.getItem('cueTheme')

  }
}
</script>

<style lang="less">
@import "../assets/css/rulestyle.less";

.van-icon .van-icon-arrow-left .van-nav-bar__arrow {
  color: #000 !important;
}

.van-nav-bar .van-icon {
  color: #000;
}

body {
  background-color: #f7f8fa;
}

.changebox {
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 10px;
  margin: 10px;
  padding: 10px;
  margin-bottom: 0;
  padding-bottom: 0;
  box-shadow: 0px 1px 6px 0px #d7dae2;

  .changebox-item {
    margin-right: 4.95%;
    margin-bottom: 10px;
    width: 30%;
    height: 40px;
    line-height: 40px;
    background: linear-gradient(0deg, #95b5ff, #9fdcff);

    border-radius: 10px;
    color: #ffffff;
    font-size: 14px;
    text-align: center;

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}

.onchangetab {
  background: linear-gradient(0deg, #2a6afd, #63c6ff) !important;
}

.newpepele-item {
  margin: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 1px 6px 0px #d7dae2;
  position: relative;
  margin-top: 20px;
  background: var(--w-bgcolor);

  .newpepele-title {
    width: 129px;
    height: 40px;
    line-height: 40px;
    position: absolute;
    top: -6px;
    left: 50%;
    text-align: center;
    color: #ffffff;
    transform: translateX(-50%);
    z-index: 10;

    img {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 129px;
      height: 40px;
      z-index: -1;
    }
  }

  .newpepele-img {
    width: 100%;

    img {
      width: 100%;
    }

    .newpepele-box {
      position: relative;
    }

    .time {
      font-size: 15px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;
      line-height: 23px;
      text-align: center;
      margin-top: 10px;
    }

    .circle-btn {
      position: absolute;
      top: 80px;
      left: 150px;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      cursor: pointer;
    }

    .circle-money {
      color: #ffebc9;
      position: absolute;
      bottom: 94px;
      left: 150px;
      font-family: PingFang SC;
      font-weight: 400;
      text-shadow: 2px 2px 3px #841414;
    }

    .circle-text {
      position: absolute;
      bottom: 66px;
      left: 130px;
      width: 90px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;

      color: #5c2a12;
      background: #fffadc;
      box-shadow: 2px 2px 3px 0px #841414;
      border-radius: 12px;
      text-align: center;
    }
  }

  .newpepele-btns {
    display: flex;
    align-content: center;
    justify-content: space-around;

    .newpepele-btns-item {
      margin: 10px 5px;
      height: 38px;
      width: 100%;
      background: linear-gradient(0deg, #2a6afd, #63c6ff);
      border-radius: 10px;
      font-size: 12px;
      color: #fff;
      font-weight: 400;
      line-height: 38px;
      text-align: center;
    }
  }

  .newpepele-regulation {
    .regulation-title {
      font-family: PingFang SC;
      font-weight: 400;
      color: #418beb;
      line-height: 23px;
      font-size: 14px;
    }

    .regulation-content {
      font-family: PingFang SC;
      font-weight: 400;
      margin-bottom: 5px;
      color: #303030;
      line-height: 23px;
      font-size: 14px;
    }
  }
}

.rotateing {
  animation: loading 1s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
